import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import { GlobalLink } from '@components/Link';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_connection_b38q" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_gravitas_d3ep" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_inspiration_lecl" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>rocketbatch Software as a Service Subscription Agreement</h2>
              <GlobalLink to="/de/wsa">
                German&nbsp;/&nbsp;
              </GlobalLink>

              <GlobalLink to="/en/wsa">
                  English&nbsp;
              </GlobalLink>
              <p><small>German version prevails.</small></p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>



          <div class="container-content">
<div class="body"><p>This Software as a Service Subscription Agreement ("<b>Agreement</b>") is entered into on the date you ("Customer") and the Subscription Service Provider ("rocketbatch") both sign an order form ("<b>Order Form</b>") referencing this Agreement , and such date&nbsp;shall be the "<b>Effective Date</b>".</p>
<p>BY ACCEPTING THIS AGREEMENT, BY (1) CLICKING A BOX INDICATING ACCEPTANCE, (2) EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, OR (3) USING FREE SERVICES, CUSTOMER AGREES TO THE TERMS OF THIS AGREEMENT. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERM “CUSTOMER” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES NOT HAVE SUCH AUTHORITY, OR DOES NOT AGREE WITH THESE TERMS AND CONDITIONS, SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.</p>
<br />
<p><b>Background</b></p>
<p>(A) rocketbatch has developed a mobile communication system that can be accessed through a mobile app as well as from the web, which it makes&nbsp;available to subscribers via the internet on a subscription basis.</p>
<p>(B) The Customer wishes to use the rocketbatch's Service in its internal business operations.</p>
<p>(C) rocketbatch has agreed to provide and the Customer has agreed to subscribe to and pay for rocketbatch's service subject to the terms and conditions of&nbsp;this Agreement.</p>
<br />
<p><b>Agreed terms</b></p>
<br />
<p><b>1. Definitions</b></p>
<br />
<p>Capitalized terms not otherwise defined in the Agreement shall have the meaning set out below:</p>
<p><b>Associated Companies: </b><br />
an entity that directly or indirectly controls, is controlled by, or is under common control with, a party to this Agreement. For purposes of the&nbsp;foregoing, "control" means the ownership of (i) greater than fifty percent (50%) of the voting power to elect directors of the entity, or (ii) greater&nbsp;than fifty percent (50%) of the ownership interest in the entity.</p>
<p><b>Authorized Users</b>: those employees, agents and independent contractors of Customer who are authorized by Customer to use the Services, as further described in clause 2.3;</p>
<p><b>Authorized User Data</b>: the personal data and information Authorized Users provide to rocketbatch and/or input by Customer into the Service for the purpose of creating an&nbsp;account for an Authorized User, but excluding the Customer Data and rocketbatch Data;</p>
<p><b>rocketbatch Data:</b> means (i) such information or data provided by rocketbatch to Customer as part of the Services; (ii) any meta data extracted by rocketbatch&nbsp;from Customer's use of the Services to be used to provide the Services; and (iii) any feedback or suggestions from Customer or Authorized Users to&nbsp;rocketbatch relating to the Services;</p>
<p><b>Business Day:</b> as described in the rocketbatch Service Level Agreement (SLA);</p>
<p><b>Business Hours:</b> as described in the rocketbatch Service Level Agreement (SLA);</p>
<p><b>Customer Data:</b> the data and information provided by Customer to rocketbatch and/or inputted by Customer, Authorized Users, or rocketbatch on&nbsp;Customer's behalf for the purpose of using the Services or facilitating Customer's use of the Services or data collected and processed by or for Customer&nbsp;through Customer's use of the Services, but excluding rocketbatch Data and Authorized User Data;</p>
<p><b>Claim Year:</b> means each successive period of twelve (12) months commencing on the Effective Date of this Agreement;</p>
<p><b>Confidential Information:</b> information of a party that is proprietary or confidential and is either clearly labelled as such; identified as&nbsp;Confidential Information in clause 10; and/or a reasonable person would understand to be confidential or proprietary at the time of disclosure;</p>
<p><b>Disaster Recovery Policy:</b> the rocketbatch disaster recovery policy currently in place as may be amended by rocketbatch from time to time;</p>
<p><b>Fees:</b> means Subscription Fees and any additional fees or expenses as set out in the Order Form;</p>
<p><b>Initial Subscription Term:</b> the initial term of the subscription as set out in the Order Form;</p>
<p><b>Inappropriate Content:&nbsp;</b>content which (a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive; (b) facilitates&nbsp;illegal activity; (c) depicts sexually explicit images; (d) promotes unlawful violence; (e) is discriminatory based on race, gender, color, religious&nbsp;belief, sexual orientation, disability; or (f) causes damage or injury to any person or property;</p>
<p><b>Intellectual Property Rights:</b> including without limitation, rights in patents, trademarks, service marks, trade names, other trade-identifying symbols and inventions, copyrights,&nbsp;design rights, database rights, rights in know-how, trade secrets and any other intellectual property rights arising anywhere in the world, whether&nbsp;registered or unregistered, and including applications for the grant of any such rights;</p>
<p><b>Order Form: </b>the Order Form submitted by Customer to rocketbatch with details of the Services and forming part of this Agreement;</p>
<p><b>Privacy Policy:</b> the rocketbatch privacy policy (https://rocketbatch.com/privacy-policy/).</p>
<p><b>Renewal Period:</b> has the meaning set out in clause 14.1 below;</p>
<p><b>Services:</b> means (i) use of the Software in accordance with this Agreement, (ii) use of rocketbatch Data; (iii) the provision of the Support Services and (iv) access&nbsp;to the rocketbatch hosting platform;</p>
<p><b>Software: </b>the internal communications software applications provided by rocketbatch as part of the Services and as set out in the Order Form;</p>
<p><b>Subscription Fees:</b> the subscription fees payable by Customer to rocketbatch for the User Subscriptions, as set out in the Order Form;</p>
<p><b>Subscription Term:</b> means the Initial Subscription Term and any subsequent Renewal Periods;</p>
<p><b>Support Services: </b>means the rocketbatch standard support services provided by rocketbatch in accordance with the Service Level Agreement in respect of the&nbsp;Software, including any applicable Software maintenance upgrades;</p>
<p><b>SLA Support Services Policy: </b>rocketbatch's policy for providing the Support Services to Customer and where applicable, any enhanced support&nbsp;services which may be purchased by Customer, as made available to Customer from time to time;</p>
<p><b>Taxes: </b>including without limitations, withholding, sales, use, excise, value added tax and similar taxes but shall not include taxes based on rocketbatch's gross income;</p>
<p><b>Third Party Applications: </b>means online applications and offline software products that are provided by third parties and interoperate with the Services as listed in the rocketbatch 3rd Party Use Statement Declaration Form available under rocketbatch Privacy Policy;</p>
<p><b>User Subscriptions</b>: the user subscriptions purchased by Customer in accordance with the Agreement for the number of Authorized Users as set out in the Order Form which&nbsp;entitle such Authorized Users to access and use the Services in accordance with this Agreement; and</p>
<p><b>Virus:</b> any thing or device (including any software, code, file or program) which may prevent, impair or otherwise adversely affect the&nbsp;access to or operation, reliability or user experience of any computer software, hardware or network, telecommunications service, equipment or network or&nbsp;any other service or device, including worms, trojan horses, viruses and other similar things or devices.</p>
<br />
<p><b>2. User Subscription</b></p>
<br />
<p>2.1 rocketbatch shall provide the Services during the Subscription Term in accordance with the terms set out in this Agreement.</p>
<p>2.2 Subject to the Customer purchasing the User Subscription(s), the restrictions set out in this clause 2 and the terms and conditions of this Agreement,&nbsp;rocketbatch hereby grants to the Customer a non-exclusive, non-transferable right to permit the Authorized Users to use the Services during the Subscription&nbsp;Term solely for Customer's internal communications.</p>
<p>2.3 In relation to the Authorized Users, Customer undertakes that:</p>
<p>2.3.1 the maximum number of Authorized Users that it authorizes to access and use the Services shall not exceed the number of User Subscriptions it has&nbsp;purchased from time to time. Customer further undertakes that it will not allow any User Subscription to be used by more than one per individual Authorized&nbsp;User unless it has been reassigned in its entirety to another individual Authorized User, in which case the prior Authorized User shall no longer have any&nbsp;right to access or use the Services;</p>
<p>2.3.2 each Authorized User shall keep a secure password for his use of the Services, that such password shall be changed frequently and that each&nbsp;Authorized User shall keep his password confidential.</p>
<p>2.4 Customer shall not access, store, distribute or transmit any Viruses, or any material, including without limitation Customer Data and the Authorized&nbsp;User Data, during the course of its use of the Services that:</p>
<p>2.4.1 is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;</p>
<p>2.4.2 facilitates illegal activity; or</p>
<p>2.4.3 in a manner that is otherwise illegal or causes damage or injury to any person or property; and rocketbatch reserves the right, without liability or prejudice to its other rights to Customer, to disable Customer's access to any material that breaches the provisions of this clause.</p>
<p>2.5 Customer shall not:</p>
<p>2.5.1 attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any&nbsp;portion of the Software in any form or media or by any means; or</p>
<p>2.5.2 attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software; or</p>
<p>2.5.3 access all or any part of the Services in order to build a product or service which competes with the Services; or</p>
<p>2.5.4 use the Services to provide services to third parties; or</p>
<p>2.5.5 license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services&nbsp;available to any third party except the Authorized Users, or</p>
<p>2.5.6 attempt to obtain, or assist third parties in obtaining, access to the Services, other than as provided under this clause 2.</p>
<p>2.6 Customer shall use all reasonable endeavours to prevent any unauthorized access to, or use of, the Services and, in the event of any such unauthorized&nbsp;access or use, promptly notify rocketbatch.</p>
<br />
<p><b>3. Additional User Subscriptions</b></p>
<br />
<p>Customer may, from time to time during any Subscription Term, purchase additional User Subscriptions in excess of the number set out in the Order Form for&nbsp;the additional fees set out in the Order Form and rocketbatch shall grant access to the Services to such additional Authorized Users in accordance with the&nbsp;provisions of this Agreement.</p>
<br />
<p><b>4. Services Availability and Support</b></p>
<br />
<p>4.1 rocketbatch shall use commercially reasonable endeavours to make the Services (excluding any Third Party Applications) available 24 hours a day, seven&nbsp;days a week and for at least 99.9 per cent of the time, excluding:-</p>
<p>4.1.1 any planned downtime out of Business Hours provided Customer is given 24 hours notice of such;</p>
<p>4.1.2 congestion whereby high traffic levels result in service disruption, caused by the Customer exceeding any agreed capacity;</p>
<p>4.1.3 Customer inaccessibility: if, for any reason, the Customer cannot be reached to correct an availability issue, then time will be frozen until&nbsp;rocketbatch can make contact with the Customer to begin fixing the availability issue;</p>
<p>4.1.4 issues resulting from problems caused by Customer's failure to follow agreed procedures, or caused by unauthorized changes to the Services by the&nbsp;Customer;</p>
<p>4.1.5 material breach by Customer of the terms of this Agreement; or</p>
<p>4.1.6 a force majeure event (see clause 15.8.).</p>
<p>4.2 rocketbatch will, as part of the Services and at no additional cost to Customer, provide Customer with rocketbatch's standard Support Services during&nbsp;Business Hours in accordance with rocketbatch's SLA Support Services Policy for standard Support Services in effect at the time that the Support Services are&nbsp;provided. rocketbatch may amend the SLA Support Services Policy for improvements in its sole and absolute discretion from time to time.</p>
<p>4.3 Customer may purchase enhanced support service packages separately, as may be offered by rocketbatch from time to time, as detailed in an Order Form, at&nbsp;rocketbatch's then current rates for any such additional support services (as notified to Customer by rocketbatch from time to time).</p>
<br />
<p><b>5. Charges and payment</b></p>
<br />
<p>5.1 Customer shall pay the Subscription Fees and any additional applicable fees related to the Services as set out in the Order Form to rocketbatch in&nbsp;accordance with this clause 5. Unless otherwise agreed in the Order Form, rocketbatch may invoice Customer for the Subscription Fees yearly in advance on or&nbsp;after the Effective Date and thereafter on each anniversary of the initial invoice date for the duration of the Subscription Term.</p>
<p>5.2 Unless otherwise specified in the Order Form, all Fees and approved expenses shall be paid by Customer in full and without deduction within thirty (30)&nbsp;days of the date of invoice.</p>
<p>5.3 If rocketbatch has not received payment for any invoices by the due dates and without prejudice to any other rights and remedies of rocketbatch, rocketbatch<br />
may:</p>
<p>5.3.1 by giving thirty (30) Business Days prior written notice to Customer, without liability to Customer, disable Customer's password, account and access&nbsp;to all or part of the Services and rocketbatch shall be under no obligation to provide any or all of the Services while the invoice(s) concerned remain&nbsp;unpaid; and</p>
<p>5.3.2 charge interest which shall accrue on such due amounts at the higher of (i) an annual rate equal to 4% or (ii) the standard statutory delay interest&nbsp;rate under applicable law.</p>
<p>5.4 All amounts and Fees stated or referred to in this Agreement are non-refundable and are exclusive of all Taxes. Customer shall be solely responsible&nbsp;for, and paying all applicable Taxes relating to this Agreement, and the use or access to the Services.</p>
<p>5.5 rocketbatch shall be entitled to increase the annual fees by ten percent (10%) for Subscription Fees with effect from the start of each Renewal Period.</p>
<br />
<p><b>6.&nbsp;Customer Obligations</b></p>
<br />
<p>6.1 Customer shall:</p>
<p>6.1.1 provide all necessary co-operation and information as may be reasonably required by rocketbatch in order to provide the Services. In the event of any&nbsp;delays in Customer's provision of such assistance, rocketbatch may adjust any agreed timetable or delivery schedule as reasonably necessary;</p>
<p>6.1.2 ensure that its Authorized Users use the Services in accordance with the terms and conditions of this Agreement, comply with all applicable laws and&nbsp;regulations with respect to its activities under this Agreement and only use the Services for lawful purposes. Customer shall be liable for any Authorized&nbsp;User's breach of this Agreement;</p>
<p>6.1.3 be solely responsible for procuring and maintaining its network connections and telecommunications links and all problems, conditions, delays and&nbsp;delivery failures arising from or relating to Customer's network connections or telecommunications links;</p>
<p>6.1.4 use all reasonable efforts to prevent any unauthorized access to, or use of, the Services and, in the event of any such unauthorized access or use,&nbsp;promptly notify rocketbatch;</p>
<p>6.1.5 not include any Inappropriate Content or Viruses or any other information or material, any part of which, or the accessing of which or use of which&nbsp;would be a criminal offense or otherwise unlawful including the breach of any Intellectual Property Rights of any other party. rocketbatch reserves the&nbsp;right, but is not obliged, to remove such content from where, in its sole and reasonable discretion, rocketbatch suspects such content to be Inappropriate&nbsp;Content, upon notice to Customer;</p>
<p>6.1.6 be solely responsible for moderating any content posted by Authorized Users and advising Authorized Users what they may and may not post through the&nbsp;Services by means of Customer's own policies;</p>
<p>6.1.7 be solely responsible for the accuracy, completeness, design, appropriateness, creation, maintenance, and updating thereof of all Customer Data in&nbsp;the use of the Services. rocketbatch shall not be liable for any errors or inaccuracies in any Customer Data or beyond its responsibility to accurately&nbsp;reproduce such Customer Data on Customer's instruction; and</p>
<p>6.1.8 be responsible for obtaining all necessary licenses and consents required to use Customer Data (if any, and including but not limited to those from&nbsp;the owners or licensees of any third party information) and as part of the Services and Customer warrants and represents that such licenses and consents&nbsp;have been obtained.</p>
<br />
<p><b>7. Warranty</b></p>
<br />
<p>7.1 rocketbatch warrants that the Services (excluding any Third Party Application) will be performed with reasonable skill and care for the Subscription&nbsp;Term.</p>
<p>7.2 The warranty provided in clause 7.1 shall not apply to the extent of any non-conformance which is caused by Customer's use of the Services contrary to&nbsp;rocketbatch's' instructions, or modification or alteration of the Services by any party other than rocketbatch or rocketbatch's duly authorized contractors or&nbsp;agents. If the Services do not conform with the warranty provided in clause 7.1, rocketbatch will, at its expense, use commercially reasonable efforts to&nbsp;correct any such non-conformance promptly, or provide the Customer with an alternative means of accomplishing the desired performance. Such correction or&nbsp;substitution constitutes Customer's sole and exclusive remedy, and rocketbatch's sole and exclusive liability for any breach of the warranty. Notwithstanding the foregoing, rocketbatch:</p>
<p>7.2.1 does not warrant that Customer's use of the Services will be uninterrupted or error-free; or that the Services and/or the information obtained by&nbsp;Customer through the Services will meet Customer's requirements;</p>
<p>7.2.2 is not responsible for any delays, delivery failures, or any other loss or damage resulting from Customer's access to and use of the Services through&nbsp;third party applications (such as Google) or the transfer of data over communications networks and facilities, including the internet, and Customer&nbsp;acknowledges that the Services may be subject to limitations, delays and other problems inherent in the use of such communications facilities;</p>
<p>7.2.3 is not responsible for any Virus which was not detected by rocketbatch using reasonable current commercial methods of detection;</p>
<p>7.2.4 nor its suppliers, shall have any liability whatsoever for the accuracy, completeness, or timeliness of Customer Data, or for any decision made or&nbsp;action taken by Customer, any Authorized User, or any third party in reliance upon any Customer Data.</p>
<p>7.3 Except as expressly provided for in this clause 7, rocketbatch (and its Associated Companies and its suppliers) disclaim all other warranties, express,&nbsp;implied or statutory, including warranties, terms and conditions of merchantability, accuracy, correspondence with description, fitness for a particular&nbsp;purpose or use and satisfactory quality, and non-infringement.</p>
<p>7.4 If Customer installs or enables Third Party Applications for use with Services, Customer acknowledge that rocketbatch may allow providers of those&nbsp;Third Party Applications to access Customer Data and Authorized User Data as required for the interoperation of such Third Party Applications with the&nbsp;Services. rocketbatch shall not be responsible for any disclosure, modification or deletion of Customer Data and Authorized User Data resulting from any such&nbsp;access by Third Party Application providers, and any such access and use of Customer Data and Authorized User Data shall be subject to the privacy policies of such Third Party Application provider. The Services shall allow Customer to restrict such access by restricting Authorized Users from installing or&nbsp;enabling such Third Party Applications for use with the Services.</p>
<p>7.5 Services may be subject to other limitations as indicated on the Order Form, such as, for example, limits on the used storage, on the used bandwidth&nbsp;and/or on the size of attachments such as photos or files.</p>
<p>7.6 NOTWITHSTANDING THE FOREGOING, BEEKEEPER:&nbsp;</p>
<p>7.6.1 DOES NOT WARRANT THAT CUSTOMER'S USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; OR THAT THE SERVICES AND/OR THE INFORMATION OBTAINED BY CUSTOMER THROUGH THE SERVICES WILL MEET CUSTOMER'S REQUIREMENTS;&nbsp;</p>
<p>7.6.2 IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR ANY OTHER LOSS OR DAMAGE RESULTING FROM CUSTOMER’S ACCESS TO AND USE OF THE SERVICES THROUGH THIRD PARTY APPLICATIONS (SUCH AS GOOGLE) OR THE TRANSFER OF DATA OVER COMMUNICATIONS NETWORKS AND FACILITIES, INCLUDING THE INTERNET, AND CUSTOMER ACKNOWLEDGES THAT THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS AND OTHER PROBLEMS INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES;&nbsp;</p>
<p>7.6.3 IS NOT RESPONSIBLE FOR ANY VIRUS THAT WAS NOT DETECTED BY BEEKEEPER USING REASONABLE CURRENT COMMERCIAL METHODS OF DETECTION;&nbsp;</p>
<p>7.6.4 NOR ITS SUPPLIERS, WILL HAVE ANY LIABILITY WHATSOEVER FOR THE ACCURACY, COMPLETENESS, OR TIMELINESS OF CUSTOMER DATA, OR FOR ANY DECISION MADE OR ACTION TAKEN BY CUSTOMER, ANY AUTHORIZED USER, OR ANY THIRD PARTY IN RELIANCE UPON ANY&nbsp; CUSTOMER DATA.&nbsp; EXCEPT AS EXPRESSLY PROVIDED FOR IN THIS SECTION 7, BEEKEEPER (AND ITS ASSOCIATED COMPANIES AND ITS SUPPLIERS), ALL SERVICES ARE PROVIDED "AS IS" AND BEEKEEPER HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHER, AND BEEKEEPER SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE.</p>
<br />
<p><b>8. Customer Data.&nbsp;</b></p>
<br />
<p>8.1 Customer shall own all right, title and interest in and to all of the Customer Data and shall have sole responsibility for the legality,&nbsp;appropriateness, completeness, reliability, integrity, accuracy and quality of the Customer Data and Authorized User Data.</p>
<p>8.2 Solely to enable rocketbatch to provide the Services to Customer, Customer grants rocketbatch and its Associated Companies a non-exclusive licence for the&nbsp;Subscription Term and 30 days afterwards to (i) host, copy, transmit and display Customer Data and to incorporate the Customer Data with the rocketbatch Data&nbsp;and (ii) where necessary, to transfer Customer Data to Third Party Application providers used by rocketbatch, only as required for the provision of the&nbsp;Services and in accordance with rocketbatch's Privacy Policy.</p>
<p>8.3 Customer acknowledges that responsibility for all Customer Data and any communications with others or between Authorized Users using the Services is&nbsp;the sole and exclusive responsibility of Customer and that rocketbatch will not be held responsible in any way for any copyright infringement or violation,&nbsp;or the violation of any other person's rights or the violation of any laws, including but not limited to infringement or misappropriation of copyright,&nbsp;trademark or other property right of any person or entity, arising or relating to Customer Data. Customer agrees to indemnify and hold harmless rocketbatch&nbsp;from and against all losses, damages, actions or causes of action, suits, claims, demands, penalties and interest arising in connection with or out of any&nbsp;such Customer Data, to the extent not caused by rocketbatch's willful misconduct.</p>
<p>8.4 rocketbatch shall follow its safeguarding procedures for Customer Data and Authorized User Data as set out in its Disaster Recovery Policy as such&nbsp;document may be amended by rocketbatch in its sole discretion from time to time, which shall be made available to Customer upon request. In the event of&nbsp;any loss or damage to Customer Data, Customer's sole and exclusive remedy shall be for rocketbatch to use commercially reasonable efforts to restore the&nbsp;lost or damaged Customer Data from the latest back-up of such Customer Data maintained by rocketbatch in accordance with the archiving procedure&nbsp;described in its Disaster Recovery Policy. rocketbatch shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data&nbsp;caused by any third party, (except those third parties sub-contracted by rocketbatch to perform services related to Customer Data maintenance and<br />
back-up) unless solely caused by rocketbatch' negligence or willful misconduct.</p>
<p>8.5 Customer acknowledges that rocketbatch is not obliged to edit, moderate or modify Customer Data (including third party information). However, rocketbatch&nbsp;reserves the right to remove any Customer Data (or third party information) which rocketbatch reasonably believes breaches any laws or regulations or any&nbsp;third party's rights or this Agreement and/or is deemed Inappropriate Content. rocketbatch will notify Customer if it removes any Customer Data (or third&nbsp;party information) in accordance with this clause. rocketbatch disclaims all liability of any kind in respect of Customer Data, third party information and&nbsp;any other material which can be accessed using the Services. rocketbatch expressly disclaims all liability for any fraud committed in connection with the&nbsp;Services.</p>
<br />
<p><b>9. Data Protection</b></p>
<br />
<p>9.1 rocketbatch acknowledges that the Customer is acting as a data controller in respect of any Authorized User Data (or Customer Data containing personal&nbsp;data). To the extent that rocketbatch has access to and processes any such Authorized User Data (or Customer Data containing personal data) in the provision&nbsp;of the Services, rocketbatch shall use reasonable endeavors to:</p>
<p>9.1.1 keep all personal data of Customer and Customer's Authorized Users secure and have in place appropriate technical and organizational measures to&nbsp;ensure an appropriate level of security for the processing of such personal data of Customer and Customer's Authorized Users and to protect such personal&nbsp;data of Customer and Customer's Authorized Users against unauthorized or unlawful processing or accidental loss, destruction or damage;</p>
<p>9.1.2 preserve the integrity of such personal data of Customer and Customer's Authorized Users and to prevent the loss or corruption of the personal data&nbsp;of Customer and Customer's Authorized Users;</p>
<p>9.1.3 only process such personal data of Customer and Customer's Authorized Users in accordance with the instructions and directions of the Customer and&nbsp;Customer's Authorized Users; and</p>
<p>9.1.4 immediately and no later than 72 hours inform Customer of any security breach, loss, data leak, or unauthorized disclosure with respect to any personal data (collectively, “<b>Data Breach</b>”), investigate the Data Breach, take all necessary steps to close the cause of the Data Breach and prevent recurrence (to the extent such cause is within the control of rocketbatch); and</p>
<p>9.1.5 provide such reasonable assistance and information to the Customer as it may reasonably require to allow the Customer to comply with its obligations&nbsp;under the General Data Protection Regulation (EU) 2016 / 679.</p>
<p>9.2 For the purposes of this Clause 9 the terms <b>"data controller"</b>, <b>"personal data"</b>, <b>"process"</b> and <b>"processing"</b> shall have the meaning set out in the General Data Protection Regulation (EU) 2016 / 679.</p>
<p>9.3&nbsp;rocketbatch shall comply at all times with all applicable privacy laws.</p>
<br />
<p><b>10. Confidentiality</b></p>
<br />
<p>10.1 Each party may be given access to Confidential Information from the other party in order to perform its obligations under this Agreement. A party's&nbsp;Confidential Information shall not be deemed to include information that:</p>
<p>(a) is or becomes publicly known other than through any act or omission of the receiving party;</p>
<p>(b) was in the other party's lawful possession before the disclosure;</p>
<p>(c) is lawfully disclosed to the receiving party by a third party without restriction on disclosure;</p>
<p>(d) is independently developed by the receiving party, which independent development can be shown by written evidence; or</p>
<p>(e) is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.</p>
<p>10.2 Each party shall hold the other's Confidential Information in confidence and, unless required by law, not make the other's Confidential Information&nbsp;available to any third party, or use the other's Confidential Information for any purpose other than the implementation of this Agreement. Each party shall&nbsp;take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or&nbsp;agents in violation of the terms of this Agreement. Neither party shall be responsible for any loss, destruction, alteration or disclosure of Confidential&nbsp;Information caused by any third party.</p>
<p>10.3 Customer acknowledges that details of the Software, Services, rocketbatch Data and the results of any performance tests of the Services, constitute&nbsp;rocketbatch's Confidential Information.&nbsp;rocketbatch acknowledges that the Customer Data is the Confidential Information of Customer.</p>
<p>10.4 rocketbatch has the right to name Customer as a reference for marketing purposes except where Customer explicitly informs rocketbatch otherwise in writing,&nbsp;provided Customer is acting reasonably.</p>
<br />
<p><b>11. rocketbatch IP Ownership</b></p>
<br />
<p>Customer acknowledges and agrees that rocketbatch and/or its licensors own all Intellectual Property Rights in the Software, Services and rocketbatch Data.&nbsp;Except as expressly stated herein, this Agreement does not grant Customer any rights to, or in, patents, copyrights, database rights, trade secrets, trade&nbsp;names, trademarks (whether registered or unregistered), or any other rights or licenses in respect of the Software, Maintenance Releases, Services or&nbsp;rocketbatch Data.</p>
<br />
<p><b>12. Indemnity</b></p>
<br />
<p>12.1 rocketbatch will defend Customer against any claim, demand, suit or proceeding made or brought against Customer by a third party alleging that the use of&nbsp;a purchased Service in accordance with this Agreement infringes or misappropriates such third party's intellectual property rights (a " <b>Claim</b>"), and will indemnify Customer from any damages, attorney fees and costs finally awarded against Customer as a result of, or for&nbsp;amounts paid by Customer under a court-approved settlement of a Claim, provided Customer (a) gives rocketbatch written notice of any Claim, (b) give&nbsp;rocketbatch sole control of the defense and settlement of the Claim (except that rocketbatch may not settle any Claim unless it unconditionally releases&nbsp;Customer of all liability), and (c) give rocketbatch all reasonable assistance, at rocketbatch's expense. If rocketbatch receives information about a Claim,&nbsp;rocketbatch may in its discretion and at no cost to Customer (i) modify the Service so that it no longer infringes or misappropriates, without breaching the&nbsp;warranty under Section 7.1 (Warranties), (ii) obtain a license for Customer's continued use of that Service in accordance with this Agreement, or (iii)&nbsp;terminate Customer's subscription for that Service and refund Customer any prepaid Fees covering the remainder of the Subscription Term. The above defense&nbsp;and indemnification obligations do not apply to the extent a Claim arises from:</p>
<p>(a) the Third Party Applications or Customer's use thereof;</p>
<p>(b) a modification of the Services by anyone other than rocketbatch or its subcontractors;</p>
<p>(c) Customer's use of Services otherwise than in accordance with the Agreement and/or Order Form;</p>
<p>(d) Customer's use of the Services in connection with Third Party Applications or any other third party services or products where such services or&nbsp;products cause the Claim; or</p>
<p>(e) Customer's use of the Services after notice of the alleged or actual Claim from rocketbatch or the appropriate authority.</p>
<p>12.2 Customer will defend rocketbatch against any claim, demand, suit or proceeding made or brought against rocketbatch by a third party alleging that Customer&nbsp;Data, or Customer's use of any Service infringes or misappropriates such third party's intellectual property rights or violates applicable law (a "<b>Claim&nbsp;Against rocketbatch</b>"), and will indemnify rocketbatch from any damages, attorney fees and costs finally awarded against rocketbatch as a result of, or for any&nbsp;amounts paid by rocketbatch under a court-approved settlement of, a Claim Against rocketbatch, provided rocketbatch (a) promptly gives Customer written notice of&nbsp;the Claim Against rocketbatch, (b) gives Customer sole control of the defence and settlement of the Claim Against rocketbatch (except that Customer may not&nbsp;settle any Claim Against rocketbatch unless it unconditionally releases rocketbatch of all liability), and (c) gives Customer all reasonable assistance, at&nbsp;Customer's expense.</p>
<br />
<p><b>13. Limitation of liability</b></p>
<br />
<p>13.1 The exclusions in this section&nbsp;13 shall apply to the fullest extent permissible at law but neither party excludes liability for death or personal injury caused by its negligence or that&nbsp;of its officers, employees, contractors or agents; fraud or fraudulent misrepresentation; or any other liability which cannot be excluded by law.</p>
<p>13.2 Except with respect to amounts owed by Customer to rocketbatch hereunder and save in the event of a claim under clause 10 or clause 12, the aggregate liability of each party for or in&nbsp;respect of any loss or damage suffered by the other party (whether due to breach of contract, tort (including negligence) or otherwise) under or in&nbsp;connection with this Agreement in any Claim Year shall be limited to the total amount of Fees paid by Customer during such Claim Year.</p>
<p>13.3 To the maximum extent permitted by applicable law, in no event will either party be liable to the other for special, consequential, incidental or other&nbsp;indirect damages, or for loss of profits, anticipated savings, business opportunity, goodwill, or loss of revenue, loss of use or loss of data (including&nbsp;corruption of data), or costs of procurement of substitute goods or services arising of this Agreement, howsoever caused and under any theory of liability&nbsp;(including contract, tort, negligence or otherwise) even if the other party has been advised of the possibility of such damages.</p>
<p>13.4 In addition to the other exclusions set out in this clause 13, rocketbatch has no liability where any failure to provide the Services is caused by:</p>
<p>13.4.1 a network, hardware or software fault in equipment which is not under the control of rocketbatch;</p>
<p>13.4.2 any act or omission of Customer;</p>
<p>13.4.3 use of the Services contrary to this Agreement; or</p>
<p>13.4.4 any unauthorized access to the Services including a malicious security breach.</p>
<p>13.5 In the event of any loss or damage to Customer Data, Customer's sole and exclusive remedy shall be as set out in clause 8.4.</p>
<br />
<p><b>14. Term and Termination</b></p>
<br />
<p>14.1 This Agreement shall, unless otherwise stated in the Order Form or terminated as provided in clause 12.1 or this clause 14, commence on the Effective Date and shall continue for the Initial Subscription Term. Thereafter, unless stated to the contrary in an Order Form, this&nbsp;Agreement automatically renews for successive periods of 12 months (each a "<b>Renewal Period</b>"), unless either party terminates with sixty&nbsp;(60) days written notice prior to the end of the Initial Subscription Term or relevant Renewal Period or otherwise terminates in accordance with the&nbsp;provisions of this Agreement. The Initial Subscription Term together with any subsequent Renewal Periods shall constitute the " <b>Subscription Term</b>". Without a Renewal Period in place, Customer's access and use of the Service shall automatically terminate.</p>
<p>14.2 Without prejudice to any other rights or remedies to which the parties may be entitled, either party may terminate this Agreement without liability to&nbsp;the other at any time with immediate effect upon written notice if the other party:</p>
<p>14.2.1 is in material breach of any of its obligations under this Agreement and, in the case of a breach which is capable of remedy, fails to remedy such&nbsp;breach within thirty (30) days following notice of the breach; or</p>
<p>14.2.2 voluntarily files a petition under bankruptcy or insolvency law; shall have a receiver or administrative receiver appointed over it or any of its&nbsp;assets; or if the other party shall become subject to an administration order or shall enter into any voluntary arrangement with its creditors or shall&nbsp;cease or threaten to cease to carry on business; or is subject to any analogous event or proceeding in any applicable jurisdiction.</p>
<p>14.3 On termination of this Agreement for any reason:</p>
<p>14.3.1 all right of use granted under this Agreement shall immediately terminate;</p>
<p>14.3.2 Customer shall cease the use of the Services;</p>
<p>14.3.3 Customer shall promptly pay all monies due under this Agreement;</p>
<p>14.3.4 each party shall return and make no further use of any equipment, property, Software and Services and other items (and all copies of them) belonging&nbsp;to the other party;</p>
<p>14.3.5 rocketbatch may destroy or otherwise dispose of any of Customer Data in its possession unless rocketbatch receives, no later than thirty (30) days after&nbsp;the effective date of the termination of this Agreement, a written request for the delivery to Customer of the then most recent back-up of the Customer&nbsp;Data. rocketbatch shall deliver the back-up to Customer following its receipt of such a written request. After such 30 day period, rocketbatch shall have no&nbsp;obligation to maintain or provide Customer Data, and shall thereafter delete and destroy all copies of Customer Data in rocketbatch's control, unless&nbsp;prohibited by law.</p>
<br />
<p><b>15. General</b></p>
<br />
<p>15.1 <b>Entire Agreement</b>. This Agreement together with any Order Form sets out the entire agreement and understanding between the parties and&nbsp;supersedes any previous agreement between the parties relating to its subject matter. Unless otherwise expressly agreed in writing this Agreement applies&nbsp;in place of and prevails over any terms or conditions contained in or referred to in any correspondence or elsewhere or implied by trade custom or course&nbsp;of dealing. Any general terms of business or other terms and conditions of any order or other document issued by Customer in connection with this Agreement&nbsp;shall not be binding on rocketbatch. In entering into this Agreement each party acknowledges and agrees that it has not relied on any representations made by&nbsp;the other except as set forth in this Agreement. Any such representations are excluded. Nothing in this clause shall limit liability for any&nbsp;representations made fraudulently.</p>
<p>15.2 <b>Waiver</b>. A waiver of any right under this Agreement is only effective if it is in writing and it applies only to the party to whom the&nbsp;waiver is addressed and to the circumstances for which it is given. Unless specifically provided otherwise, rights arising under this Agreement are&nbsp;cumulative and do not exclude rights provided by law.</p>
<p>15.3 <b>Invalid provisions</b>. If any provision (or part of a provision) of this Agreement is found by any court or administrative body of&nbsp;competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force. If any invalid, unenforceable or illegal&nbsp;provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give&nbsp;effect to the commercial intention of the parties.</p>
<p>15.4 <b>Governing Law and Jurisdiction</b>. Save for any other agreement as stated in the Order Form:</p>
<p>15.4.1 For all North America based Customers (USA, Canada, Mexico), the parties hereby exclude application to this Agreement of the 1980 United Nations Convention on Contracts for the International Sale of Goods; rather, this Agreement, and all dispute arising out of or related thereto, will be governed by and construed under the laws of the State of Delaware without reference to conflict of laws principles.&nbsp; All such disputes will be subject to the exclusive jurisdiction of the state and federal courts located in location mutually agreed upon in the State of Delaware, and the parties agree and submit to the personal and exclusive jurisdiction and venue of these courts.&nbsp; In the event of any litigation between the parties, the prevailing party will be entitled to reasonable attorneys' fees and all costs incurred in enforcing this Agreement.</p>
<p>15.4.2 For all legally registered entities in Germany as a party to this Agreement and all dispute arising out of or related thereto, will be governed by and construed under the laws of Germany without reference to conflict of laws principles. &nbsp;All such disputes will be subject to the exclusive jurisdiction of the state and federal courts located in location mutually agreed upon in Berlin, and the parties agree and submit to the personal and exclusive jurisdiction and venue of these courts. &nbsp;In the event of any litigation between the parties, the prevailing party will be entitled to reasonable attorneys' fees and all costs incurred in enforcing this Agreement.</p>
<p>15.4.3 For all non Germany and non North America based Customers (USA, Canada, Mexico) all disputes arising out of or in connection with this Agreement shall be governed exclusively by substantive Swiss law excluding the conflict of law rules and the Laws in treaties including but not limited to the Uniform Law on Purchases (Vienna treaty). The Commercial Court of the Canton of Zurich shall have exclusive jurisdiction to settle any dispute which may arise out of or in connection with this Agreement, including its conclusion.</p>
<p>15.5<b> Third Party Rights</b>. A person who is not a party to this Agreement has no rights to enforce, or to enjoy the benefit of, any term of&nbsp;this Agreement.</p>
<p>15.6<b> Sub-contracting and Assignment</b>. Neither rocketbatch nor Customer may assign or otherwise transfer this Agreement or any of its rights or&nbsp;obligations or purport to do any such acts under it to any third party without prior written consent from the other party, such consent not to be&nbsp;unreasonably withheld. Notwithstanding anything to the contrary, both Parties shall have the right, upon written notice to the other Party, to assign this Agreement&nbsp;to any of its Associated Companies, or to an entity resulting from a merger, acquisition or other business reorganization of the impacted Party. In addition,&nbsp;rocketbatch shall have the right to sub-contract any of its obligations hereunder to a third party, provided that rocketbatch shall continue to remain&nbsp;responsible for the performance of the Services hereunder. Any attempted assignment, sub-contracting or other transfer in violation of this provision shall&nbsp;be null and void.</p>
<p>15.7<b> No partnership or agency</b>. Nothing in this Agreement is intended to or shall operate to create a partnership between the parties, or&nbsp;authorize either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind&nbsp;the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the&nbsp;exercise of any right or power).</p>
<p>15.8 <b>Force Majeure.</b> rocketbatch shall have no liability to Customer under this Agreement if it is prevented from or delayed in performing its&nbsp;obligations under this Agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including,&nbsp;without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce of rocketbatch or any other party), failure of a utility&nbsp;service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, fire, flood or storm. rocketbatch shall provide&nbsp;Customer with notice of such an event and its expected duration.</p>
<p>15.9 <b>Notices</b>. All notices to be given under this Agreement shall be given in English in writing to the address as shall be given by either Party to the other in&nbsp;writing. Any notice involving non-performance, termination, or renewal shall be hand-delivered or sent by recognized overnight courier or by certified&nbsp;mail, return receipt requested. Notices given by rocketbatch regarding price changes, discount category changes, product discontinuance, product changes, and&nbsp;logistics centre changes may in addition be sent by e-mail to the person(s) specified by Customer from time to time. All other notices not referred to&nbsp;elsewhere in this clause 15.9 or this Agreement, may be sent by (i) post or (ii) e-mail (info@rocketbatch.com). All notices shall be deemed to have been given and received on the earlier of actual&nbsp;receipt or three (3) days from the date of postmark or date and time an email is sent.</p>
<p>15.10 <b>Variations</b>. Save as otherwise expressly stated in this Agreement, this Agreement may only be modified or varied in writing executed&nbsp;by duly authorized representatives of both parties.</p>
<p>15.11 <b>Survival</b>. In addition to those provisions which by their nature are intended to survive any termination of this Agreement, clauses&nbsp;9, 10, 11, 12, 13, 14.3 and 15, of this Agreement shall survive such termination or expiration of this Agreement.</p>
<p>15.12 <b>Export Control</b>. The Service, Software and other rocketbatch materials are subject to the export control laws of various countries,&nbsp;including without limitation the laws of the United States, Switzerland and the UK. Customer agrees that it will not submit the Service or other rocketbatch&nbsp;materials to any government agency for licensing consideration or other regulatory approval without the prior written consent of rocketbatch, and will not&nbsp;export the Service, Software, rocketbatch Data and rocketbatch materials to countries, persons or entities prohibited by such laws. Customer shall also be&nbsp;responsible for complying with all applicable governmental regulations of the country where Customer is registered, and any foreign countries with respect&nbsp;to the use of the Service or other rocketbatch materials by Customer and its Authorized Users. Customer will not engage in any activity that would cause&nbsp;rocketbatch to be in violation of any such export control laws and regulations.</p>

</div>
</div>



        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 100px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 150px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;